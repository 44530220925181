import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import iassign from "immutable-assign"
import _ from "lodash"
import React, { useRef, useState } from "react"
import { useHistory } from "react-router"
import { CustomInput, Label, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"

import Auth from "../../Auth/Auth"
import { excludePropertyArray } from "../../helpers/object"
import { CreateListInput, ListType, MeFragment, useCreateListMutation, ListMemberIdType } from "../../__generated__/graphql"
import EditButtons from "../ui/EditButtons"
import { FormInput } from "../ui/Forms/FormInput"
import { GetLookupDataToOptions } from "../ui/LookupOptions"


interface AddListProps {
  modalOpen: boolean
  setModalOpen: (value: boolean) => void
  user: MeFragment
  auth: Auth
}

export const AddListModal: React.FC<AddListProps> = ({
  modalOpen,
  setModalOpen,
  user,
  auth,
}) => {
  const [createList] = useCreateListMutation()
  const [dynamicType, setDynamicType] = useState("org")
  const [saving, setSaving] = useState(false)
  const history = useHistory()
  const nameRef = useRef<HTMLInputElement>(null)

  const defaultList ={
    __typename: "CreateListInput",
    name: "",
    type: ListType.Dynamic,
    owner: user?.person?.id,
    hidden: false,
  } as CreateListInput
  const [list, setList] = useState<CreateListInput>(defaultList)

  const resetModal = () => {
    setDynamicType("org")
    setSaving(false)
    setList(defaultList)
  }

  const onSubmit = () => {
    if(list.name.length === 0){
      nameRef?.current?.focus()
      console.log(nameRef.current)
      return
    }
    setSaving(true)
    let input = excludePropertyArray(list, ["__typename"]) as CreateListInput
    if(input.type === ListType.Dynamic){
      if(dynamicType === "org"){
        input = {...input, dynamicFilters: {org: {}}, itemIdTypes: [ListMemberIdType.org_id]}
      } else if (dynamicType === "plan"){
        input = {...input, dynamicFilters: {plan: {}}, itemIdTypes: [ListMemberIdType.fund_num]}
      } else if (dynamicType === "portfolio"){
        input = {...input, dynamicFilters: {portfolio: {}}, itemIdTypes: [ListMemberIdType.portfolio_num]}
      } else if (dynamicType === "product"){
        input = {...input, dynamicFilters: {product: {}}, itemIdTypes: [ListMemberIdType.product_id]}
      } else if (dynamicType === "vehicle"){
        input = {...input, dynamicFilters: {vehicle: {}}, itemIdTypes: [ListMemberIdType.fundid]}
      }
    }

    createList({ variables: { input } })
    .then(result => {
      if (result && result.data) {
        setSaving(false)
        history.push(`/lists/${result.data.createList?.list?.id}?editMode=true`)
      }
    })
    .catch(err => {
      setSaving(false)
      console.log("Error testManagerSummary", err.message)
      // throw new Error(`${err.message}`)
    })
  }

  const closeModal = () => {
    resetModal()
    setModalOpen(false)
  }

  const handleChange = (property:string, value:any) => {
    const newState = iassign(
      list,
      [property],
      obj => {
        return value
      }
    )
    setList(newState)
  }

  return (
    <>
      <Modal size="md" className="mt-5" isOpen={modalOpen} toggle={() => setModalOpen(!modalOpen)} zIndex={1500}>
        <ModalHeader className="fee-modal-header full-width-header">
          <div className="d-flex justify-content-between">
            <div>
              New List
            </div>
            <div onClick={() => closeModal()}>
              <FontAwesomeIcon
                icon="times"
                className="ml-auto"
              />
            </div>
          </div>
        </ModalHeader>
        <ModalBody>
        <h5>Name</h5>
        <FormInput
          property={"name"}
          displayName={""}
          type={"text"}
          placeholder={"List Name"}
          idx={1}
          editMode={true}
          propertyVal={list.name}
          updateValue={(value) => handleChange('name', value)}
          required={true}
          inputRef={nameRef}
          validateAlsoOnChange={true}
        />
        <h5 className="mt-4">Choose list type</h5>
        <CustomInput
          id={"2-dynamic"}
          className="boolean-radio mt-3 full-width"
          bsSize="sm"
          type="radio"
          value="true"
          checked={list.type === ListType.Dynamic}
          onChange={(value) => value.target.value === "true" && handleChange('type', ListType.Dynamic)}
          label="Dynamic"
        />
        <Label className="pl-4 text-gray-50" for="2-dynamic">Generate a list using a saved search.</Label>
        {list.type === ListType.Dynamic &&
          <div className="pl-4">
            <h5>What type of items should this list contain?</h5>
            <FormInput
              property={"dynamic-type"}
              displayName={""}
              type={"select"}
              idx={3}
              editMode={true}
              options={GetLookupDataToOptions({
                data: [
                  {
                    code: "org",
                    value: "Organization",
                  },
                  {
                    code: "plan",
                    value: "Plan",
                  },
                  {
                    code: "portfolio",
                    value: "Portfolio",
                  },
                  {
                    code: "product",
                    value: "Product",
                  },
                  {
                    code: "vehicle",
                    value: "Vehicle",
                  },
                ],
                multiple: true,
              })}
              propertyVal={dynamicType}
              updateValue={(value) => setDynamicType(value)}
              required={true}
            />
          </div>
        }
        <CustomInput
          id={"4-static"}
          className="boolean-radio mt-3 full-width"
          bsSize="sm"
          type="radio"
          value="true"
          checked={list.type === ListType.Static}
          onChange={(value) => value.target.value === "true" && handleChange('type', ListType.Static)}
          label="Static"
        />
        <Label className="pl-4 text-gray-50" for="4-static">Add individual items (like plans and products) to create a list.</Label>
        <CustomInput
          id={"5-composite"}
          className="boolean-radio mt-3 full-width"
          bsSize="sm"
          type="radio"
          value="true"
          checked={list.type === ListType.Composite}
          onChange={(value) => value.target.value === "true" && handleChange('type', ListType.Composite)}
          label="Composite"
        />
        <Label className="pl-4 text-gray-50" for="5-composite">Combine existing lists and choose which items to exclude</Label>
        </ModalBody>
        <ModalFooter>
          <EditButtons editMode={true} setEditMode={() => true} cancelEdit={() => closeModal()} saving={saving} onSubmit={onSubmit} disableOnError={true}/>
        </ModalFooter>
      </Modal>
    </>
  )
}