import { disableFragmentWarnings, gql } from "@apollo/client"

import {
  PRODUCT_ASSETS_CLIENT_TYPE_FRAGMENT,
  PRODUCT_ASSETS_REGION_FRAGMENT,
  PRODUCT_EMPLOYEES_FRAGMENT,
  PRODUCT_ESG_FRAGMENT,
  PRODUCT_GP_COMMITMENTS_FRAGMENT,
  PRODUCT_GUIDELINES_FRAGMENT,
  PRODUCT_MANAGEMENT_FRAGMENT,
  PRODUCT_MANAGEMENT_PASSIVE_FRAGMENT,
  PRODUCT_PROCESS_FRAGMENT,
  PRODUCT_SUMMARY_FRAGMENT,
  PRODUCT_ASSETS_VEHICLES_FRAGMENT,
  PRODUCT_CHARACTERISTICS_FRAGMENT,
  FACT_FRAGMENT,
  OPINION_FRAGMENT,
  FILE_BASIC_INFO_FRAGMENT,
  MANAGER_NOTE_FRAGMENT,
  INTERACTION_SIDEBAR_FRAGMENT,
} from "./Fragments"
import { PRODUCT_OVERVIEW_OUTLOOK_FRAGMENT } from "./ProductFragments"
import { PERSON_SUMMARY_FRAGMENT } from "./PersonFragments"
import { CLIENT_PORTFOLIO_DETAIL_LAYOUT_FRAGMENT, PLAN_PORTFOLIO_FRAGMENT } from "./ClientFragments"
import { REPORT_PERFORMANCE_COMPARISON_FETCH_FRAGMENT } from "./PerformanceComparisonFragments"

disableFragmentWarnings()

export const PRODUCT_BASIC_QUERY = gql`
  query ProductBasic($id: Int!) {
    product(id: $id) {
      product {
        id
        name
        inactive
        assetClass {
          id
          parent {
            id
          }
        }
        manager {
          id
          name
        }
        outlookSurvey {
          inactive
          editable
        }
        layout {
          sections {
            type
          }
        }
      }
    }
  }
`

const PRODUCT_PROFILE_CLOSED_END_FRAGMENT = gql`
  fragment ProductProfileClosedEndFragment on ClosedEndedFields {
    vintageYearFirstCashflow
    vintageYearLegalInception
    irrTarget {
      gross {
        low
        high
        target
      }
      net {
        low
        high
        target
      }
    }
    targetTotalValuePaidIn {
      gross {
        low
        high
        target
      }
      net {
        low
        high
        target
      }
    }
    targetRaise
    fundRaising {
      date
      committedCapital
    }
    controlRights {
      code
      value
    }
    projectedCloseDates
    minimumCommitment
    sponsorCommitment
    investmentPeriod
    term
    extensions {
      numberAllowed
      lengthAllowed
    }
    dealPolicies {
      dealSize {
        lower
        higher
      }
      developmentLimits {
        lower
        higher
      }
      singleAssetLimits {
        lower
        higher
      }
      trailing12mEBITDA {
        lower
        higher
      }
      revenue {
        lower
        higher
      }
      enterpriseValue {
        lower
        higher
      }
      numberOfInvestments {
        lower
        higher
        average
        median
        explanation
      }
    }
    financingType {
      shortTerm {
        used
        limit
        basis {
          code
          value
        }
      }
      longTerm {
        used
        limit
        basis {
          code
          value
        }
      }
    }
  }
`

const PRODUCT_PROFILE_TARGET_DATE_FRAGMENT = gql`
  fragment ProductProfileTargetDateFragment on TargetDateFields {
    vintageYear
    allocations(filters: { startDate: $startDate, endDate: $endDate }) {
      date
      allocations {
        assetClass {
          shortName
          fullName
        }
        weight
      }
    }
    glidePathVersion {
      id
      name
      inceptionYear
      implementationPhilosophy {
        code
        value
      }
      glidePath {
        id
        name
        yearsToTermination
        detailedAllocations {
          revisionDate
          years {
            yearNumber
            allocations {
              assetClass {
                id
                shortName
              }
              targetWeight
            }
          }
        }
      }
    }
  }
`

const PRODUCT_PROFILE_EQUITY_FRAGMENT = gql`
  fragment ProductProfileEquityFragment on EquityFields {
    targetPrimaryGICSSectors {
      code
      value
    }
  }
`

const PRODUCT_PROFILE_PRIVATE_EQUITY_FRAGMENT = gql`
  fragment ProductProfilePrivateEquityFragment on PrivateEquityFields {
    targetPrimaryGICSSectors {
      code
      value
    }
    targetInvestmentStyle {
      code
      value
    }
  }
`

const PRODUCT_PROFILE_PRIVATE_CREDIT_FRAGMENT = gql`
  fragment ProductProfilePrivateCreditFragment on PrivateCreditFields {
    targetGrossYield
    targetOriginationFees
    targetInvestmentStyle {
      code
      value
    }
  }
`

const PRODUCT_PROFILE_OPEN_END_FRAGMENT = gql`
  fragment ProductProfileOpenEndFragment on OpenEndedFields {
    longShortPolicy {
      totalGrossExposure
    }
    riskStatisticsTargets {
      trackingError {
        low
        high
      }
      relativeReturn {
        low
        high
      }
      excessReturn {
        low
        high
      }
    }
  }
`

const PRODUCT_PROFILE_REAL_ASSETS_FRAGMENT = gql`
  fragment ProductProfileRealAssetsFragment on RealAssetsFields {
    primaryRealAssetSectorsTarget {
      code
      value
    }
  }
`

const VEHICLE_PROFILE_CLOSED_END_FRAGMENT = gql`
  fragment VehicleProfileClosedEndFragment on ClosedEndedVehicleFields {
    vintageFirstCashFlow
    managementFees {
      investmentPeriod {
        details
        feesByYear {
          yearNumber
          rate
        }
        feesByAum {
          aumTier
          rate
        }
        feeBasis {
          # code
          value
        }
      }
      postInvestmentPeriod {
        details
        feesByYear {
          yearNumber
          rate
        }
        feesByAum {
          aumTier
          rate
        }
        feeBasis {
          # code
          value
        }
      }
    }
  }
`

const VEHICLE_PROFILE_PRIVATE_EQUITY_FRAGMENT = gql`
  fragment VehicleProfilePrivateEquityFragment on PrivateEquityVehicleFields {
    waterfall {
      code
      value
    }
    hurdle {
      date
      hurdle
      hurdleReturnType {
        code
        value
      }
    }
    carry {
      date
      rate
    }
    offset {
      date
      rate
    }
  }
`

const VEHICLE_PROFILE_HEDGE_FUND_FRAGMENT = gql`
  fragment VehicleProfileHedgeFundFragment on HedgeFundVehicleFields {
    subjectTo25PercentLimit
    subscriptionPeriod {
      code
      value
    }
    redemptionDays
    lockupPeriod {
      code
      value
    }
    noticePeriod
    initialPayout
    finalPayout
    gateFundProvision
    gateInvestorProvision
    highWaterMark
    mostFavoredNation
    hurdleBenchmark {
      date
      benchmarkAdd
      benchmark {
        vehicle {
          name
        }
      }
    }
    rebates
    redemptionFees
    feeAtAum50: feeAtAum(filter: { aum: 50000000 }) {
      date
      fee
    }
    feeAtAum100: feeAtAum(filter: { aum: 100000000 }) {
      date
      fee
    }
  }
`

const VEHICLE_PROFILE_STABLE_FRAGMENT = gql`
  fragment VehicleProfileStableFragment on StableValueFields {
    fees {
      date
      annualizedWrapPctOfApplicableWrappedAssets
      annualizedSubAdvisedPctOfApplicableSubadvisedAssets
    }
  }
`

const VEHICLE_PROFILE_REAL_ASSETS_FRAGMENT = gql`
  fragment VehicleProfileRealAssetsFragment on RealAssetsVehicleFields {
    performanceFee {
      date
      rate
      details
    }
    acquisitionFee {
      date
      rate
      details
    }
    dispositionFee {
      date
      rate
      details
    }
  }
`

const VEHICLE_PROFILE_MUTUAL_FRAGMENT = gql`
  fragment VehicleProfileMutualFragment on MutualFundFields {
    datedFees {
      totalExpense
      date
    }
  }
`

const VEHICLE_PROFILE_EXCHANGE_FRAGMENT = gql`
  fragment VehicleProfileExchangeFragment on ExchangeTradedFundFields {
    datedFees {
      totalExpense
      date
    }
  }
`

const VEHICLE_PROFILE_POOL_FRAGMENT = gql`
  fragment VehicleProfilePoolFragment on PooledVehicleFields {
    feeAtAum50: feeAtAum(filter: { aum: 50000000 }) {
      date
      fee
    }
    feeAtAum100: feeAtAum(filter: { aum: 100000000 }) {
      date
      fee
    }
  }
`

const VEHICLE_PROFILE_ANNUITY_FRAGMENT = gql`
  fragment VehicleProfileAnnuityFragment on VariableAnnuityFields {
    datedFees {
      totalExpense
      date
    }
  }
`

const VEHICLE_PROFILE_COLLECTIVE_FUND_COMPOSITE_FRAGMENT = gql`
  fragment VehicleProfileCollectiveFundCompositeFragment on CollectiveInvestmentFundCompositeFields {
    datedFees {
      date
      totalExpense
      equalWeightedFee
    }
  }
`

const VEHICLE_PROFILE_SEPARATE_ACCOUNT_FRAGMENT = gql`
  fragment VehicleProfileSeparateAccountFragment on SeparateAccountFields {
    feeAtAum50: feeAtAum(filter: { aum: 50000000 }) {
      date
      fee
    }
    feeAtAum100: feeAtAum(filter: { aum: 100000000 }) {
      date
      fee
    }
  }
`

const VEHICLE_PROFILE_COLLECTIVE_FUND_FRAGMENT = gql`
  fragment VehicleProfileCollectiveFundFragment on CollectiveInvestmentFundFields {
    datedFees {
      date
      totalExpense
    }
  }
`

const VEHICLE_PROFILE_STABLE_SEPARATE_ACCOUNT_FRAGMENT = gql`
  fragment VehicleProfileStableSeparateAccountFragment on StableValueSeparateAccountFields {
    feeAtAum50: feeAtAum(filter: { aum: 50000000 }) {
      date
      fee
    }
    feeAtAum100: feeAtAum(filter: { aum: 100000000 }) {
      date
      fee
    }
  }
`

const VEHICLE_PROFILE_STABLE_COLLECTIVE_FRAGMENT = gql`
  fragment VehicleProfileStableCollectiveFragment on StableValueCollectiveInvestmentFundFields {
    fees {
      date
      allInFee
    }
  }
`
export const PRODUCT_PROFILE_QUERY = gql`
  query ProductProfile(
    $id: Int!
    $startDate: Date!
    $endDate: Date!
    $monthDate: Date!
    $showOpinions: Boolean!
  ) {
    product(id: $id) {
      product {
        name
        id
        capitalStructure
        inceptionDate
        assetClass {
          id
          shortName
          fullName
          parent {
            id
            shortName
          }
        }
        primaryBenchmark {
          name
        }
        targetBenchmarkAdd
        blendedBenchmark {
          percent
          benchmark {
            code
            value
          }
        }
        activePassive
        inDCMatrix
        inMaxPlatform
        callanFamilyOfficeApproved
        hardCap

        productRelatedClientExposureTotal {
          discretionaryFlag {
            code
            value
          }
          clientAssets
          numberOfClients
        }
        productRelatedClientPlanExposure {
          clientAssets
          plan {
            id
            name
            fundType {
              code
              value
            }
            reportsAndTotalFunds {
              reportId
              totalFundPid
            }
            client {
              id
              name
            }
          }
          vehicle {
            vehicle {
              id: fundid
              fundid
              name
            }
          }
        }
        inactive
        latestAum {
          date
          aum
        }
        managerSearchInfo {
          timePeriod
          semiFinalist
          placement
        }

        writeUps {
          facts {
            id
            researchCategory {
              code
              value
            }
            text
            bullets
            date
            lastUpdated
          }
          opinions @include(if: $showOpinions) {
            opinions {
              status {
                code
                value
              }
              researchCategory {
                code
                value
              }
              meritsText
              meritsBullets
              considerationsText
              considerationsBullets
              date
              client {
                id
                name
                shortName
              }
              lastUpdated
            }
            vehicle {
              code
              value
            }
          }
        }

        interactions {
          id
          type
          date
          contacts {
            id
            firstName
            lastName
          }
          callanPeople {
            id
            firstName
            lastName
          }
          subject
          notes {
            hasNotes
          }
        }

        currency {
          code
          value
        }

        primaryGeographicRegion {
          code
          value
        }
        people {
          active
          prodTitleNum {
            code
            value
          }
          yearStartedWithProduct
          person {
            id
            firstName
            lastName
            title
            active
            background {
              keyPerson
              yearStartedInIndustry
              yearStartedWithFirm
            }
          }
        }
        linkedSeries {
          linkName
          series {
            product {
              name
            }
            ... on ClosedEndedPrivateCredit {
              closedEnded {
                vintageYearFirstCashflow
                fundRaising {
                  date
                  committedCapital
                }
              }
            }
            ... on ClosedEndedPrivateEquity {
              closedEnded {
                vintageYearFirstCashflow
                fundRaising {
                  date
                  committedCapital
                }
              }
            }
            ... on ClosedEndedRealAssets {
              closedEnded {
                vintageYearFirstCashflow
                fundRaising {
                  date
                  committedCapital
                }
                targetTotalValuePaidIn {
                  net {
                    low
                    high
                  }
                  gross {
                    low
                    high
                  }
                }
              }
            }
          }
        }

        employeeBreakdownByGender(startDate: $startDate, endDate: $endDate) {
          date
          type {
            code
            value
          }
          count
        }
        employeeBreakdownByDiversityProfile(
          startDate: $startDate
          endDate: $endDate
        ) {
          date
          type {
            code
            value
          }
          count
        }
        employeeTurnover(startDate: $startDate, endDate: $endDate) {
          date
          type {
            code
            value
          }
          count
          gained
          lost
        }
        documents {
          id
          name
          description
          url
          versionId
          mimeType
          type {
            code
            value
          }
          subType {
            code
            value
          }
          versions {
            id
            updated
          }
        }
        assetsByClient(
          startDate: $startDate
          endDate: $endDate
          categoryType: TOT
        ) {
          quarterEndDate
          assetsUnderManagement
          numberOfAccounts
          clientType {
            value
          }
          categoryType {
            value
          }
        }
        assetsByVehicle(startDate: $startDate, endDate: $endDate) {
          quarterEndDate
          assetsUnderManagement
          numberOfAccounts
          vehicleType {
            value
          }
        }
        assetsByRegion(startDate: $startDate, endDate: $endDate) {
          quarterEndDate
          assetsUnderManagement
          numberOfAccounts
          region {
            value
          }
        }
        vehicles {
          vehicle {
            id: fundid
            fundid
            name
            isRepresentative
            category {
              code
              value
            }
            status {
              code
              value
            }
            inceptionDate
            monthly: defaultPerformance(
              filters: {
                period: { type: Monthly }
                startDate: $monthDate
                endDate: $monthDate
              }
            ) {
              startDate
              endDate
              value {
                active
              }
              fees {
                value
              }
            }
            quarterly: defaultPerformance(
              filters: {
                period: {
                  type: TrailingNumberOfQuarters
                  trailingNumberOfQuarters: 1
                  dateToDateEnd: $endDate
                }
                type: t
                projection: r
              }
            ) {
              startDate
              endDate
              value {
                active
              }
              fees {
                value
              }
            }
            monthlyQuarterly: defaultPerformance(
              filters: {
                period: {
                  type: TrailingNumberOfMonths
                  trailingNumberOfMonths: 3
                  dateToDateEnd: $monthDate
                }
                type: t
                projection: r
              }
            ) {
              startDate
              endDate
              value {
                active
              }
              fees {
                value
              }
            }
            yearly: defaultPerformance(
              filters: {
                period: {
                  type: TrailingNumberOfQuarters
                  trailingNumberOfQuarters: 4
                  dateToDateEnd: $endDate
                }
                type: t
                projection: r
              }
            ) {
              startDate
              endDate
              value {
                active
              }
            }
            monthlyYearly: defaultPerformance(
              filters: {
                period: {
                  type: TrailingNumberOfMonths
                  trailingNumberOfMonths: 12
                  dateToDateEnd: $monthDate
                }
                type: t
                projection: r
              }
            ) {
              startDate
              endDate
              value {
                active
              }
            }
            threeYearly: defaultPerformance(
              filters: {
                period: {
                  type: TrailingNumberOfQuarters
                  trailingNumberOfQuarters: 12
                  dateToDateEnd: $endDate
                }
                type: t
                projection: r
              }
            ) {
              startDate
              endDate
              value {
                active
              }
            }
            monthlyThreeYearly: defaultPerformance(
              filters: {
                period: {
                  type: TrailingNumberOfMonths
                  trailingNumberOfMonths: 36
                  dateToDateEnd: $monthDate
                }
                type: t
                projection: r
              }
            ) {
              startDate
              endDate
              value {
                active
              }
            }
            fiveYearly: defaultPerformance(
              filters: {
                period: {
                  type: TrailingNumberOfQuarters
                  trailingNumberOfQuarters: 20
                  dateToDateEnd: $endDate
                }
                type: t
                projection: r
              }
            ) {
              startDate
              endDate
              value {
                active
              }
            }
            monthlyFiveYearly: defaultPerformance(
              filters: {
                period: {
                  type: TrailingNumberOfMonths
                  trailingNumberOfMonths: 60
                  dateToDateEnd: $monthDate
                }
                type: t
                projection: r
              }
            ) {
              startDate
              endDate
              value {
                active
              }
            }
            sevenYearly: defaultPerformance(
              filters: {
                period: {
                  type: TrailingNumberOfQuarters
                  trailingNumberOfQuarters: 28
                  dateToDateEnd: $endDate
                }
                type: t
                projection: r
              }
            ) {
              startDate
              endDate
              value {
                active
              }
            }
            monthlySevenYearly: defaultPerformance(
              filters: {
                period: {
                  type: TrailingNumberOfMonths
                  trailingNumberOfMonths: 84
                  dateToDateEnd: $monthDate
                }
                type: t
                projection: r
              }
            ) {
              startDate
              endDate
              value {
                active
              }
            }
            tenYearly: defaultPerformance(
              filters: {
                period: {
                  type: TrailingNumberOfQuarters
                  trailingNumberOfQuarters: 40
                  dateToDateEnd: $endDate
                }
                type: t
                projection: r
              }
            ) {
              startDate
              endDate
              value {
                active
              }
            }
            monthlyTenYearly: defaultPerformance(
              filters: {
                period: {
                  type: TrailingNumberOfMonths
                  trailingNumberOfMonths: 120
                  dateToDateEnd: $monthDate
                }
                type: t
                projection: r
              }
            ) {
              startDate
              endDate
              value {
                active
              }
            }
            cashFlowsPerformanceTrue: cashFlowsPerformance(
              date: $endDate
              input: { net: true, numeraire: USADOL }
            ) {
              committedCapital
              net
              irr
              tvpi
              dpi
              rvpi
            }
            cashFlowsPerformanceFalse: cashFlowsPerformance(
              date: $endDate
              input: { net: false, numeraire: USADOL }
            ) {
              committedCapital
              net
              irr
              tvpi
            }
            identifiers {
              ticker
              cusip
            }
            datedFees {
              date
              minimumSize
              minimumAnnualFee
            }
            product {
              ... on ClosedEndedHedgeFund {
                closedEnded {
                  fundRaising {
                    date
                    committedCapital
                  }
                }
              }
              ... on ClosedEndedPrivateCredit {
                closedEnded {
                  fundRaising {
                    date
                    committedCapital
                  }
                }
              }
              ... on ClosedEndedPrivateEquity {
                closedEnded {
                  fundRaising {
                    date
                    committedCapital
                  }
                }
              }
              ... on ClosedEndedHedgeFund {
                closedEnded {
                  fundRaising {
                    date
                    committedCapital
                  }
                }
              }
            }
          }
        }
      }
      ... on OpenEndedEquity {
        # equity {
        #   ...ProductProfileEquityFragment
        # }
        openEnded {
          ...ProductProfileOpenEndFragment
        }
      }
      ... on OpenEndedPassiveEquity {
        # equity {
        #   ...ProductProfileEquityFragment
        # }
        openEnded {
          ...ProductProfileOpenEndFragment
        }
        # passiveProduct{
        #   ...ProductProfilePassiveFragment
        # }
      }
      ... on OpenEndedFixedIncome {
        openEnded {
          ...ProductProfileOpenEndFragment
        }
        # fixedIncome{
        #   ...ProductProfileFixedIncomeFragment
        # }
      }
      ... on OpenEndedPassiveFixedIncome {
        openEnded {
          ...ProductProfileOpenEndFragment
        }
        # passiveProduct{
        #   ...ProductProfilePassiveFragment
        # }
        # fixedIncome{
        #   ...ProductProfileFixedIncomeFragment
        # }
      }
      ... on OpenEndedHedgeFund {
        openEnded {
          ...ProductProfileOpenEndFragment
        }
        # hedgeFund{
        #   ...ProductProfileHedgeFragment
        # }
      }
      ... on ClosedEndedHedgeFund {
        closedEnded {
          ...ProductProfileClosedEndFragment
        }
        # hedgeFund{
        #   ...ProductProfileHedgeFragment
        # }
      }
      ... on OpenEndedMAC {
        openEnded {
          ...ProductProfileOpenEndFragment
        }
        # equity {
        #   ...ProductProfileEquityFragment
        # }
        # fixedIncome{
        #   ...ProductProfileFixedIncomeFragment
        # }
        # multiAssetClass{
        #   ...ProductProfileMACFragment
        # }
      }
      ... on OpenEndedMAC {
        openEnded {
          ...ProductProfileOpenEndFragment
        }
        # equity {
        #   ...ProductProfileEquityFragment
        # }
        # fixedIncome{
        #   ...ProductProfileFixedIncomeFragment
        # }
        # multiAssetClass{
        #   ...ProductProfileMACFragment
        # }
        # passiveProduct{
        #   ...ProductProfilePassiveFragment
        # }
      }
      ... on OpenEndedTargetDate {
        openEnded {
          ...ProductProfileOpenEndFragment
        }
        targetDate {
          ...ProductProfileTargetDateFragment
        }
        # equity {
        #   ...ProductProfileEquityFragment
        # }
        # fixedIncome{
        #   ...ProductProfileFixedIncomeFragment
        # }
      }
      ... on ClosedEndedPrivateCredit {
        closedEnded {
          ...ProductProfileClosedEndFragment
        }
        # privateCredit {
        #   ...ProductProfilePrivateCreditFragment
        # }
      }
      ... on ClosedEndedPrivateEquity {
        closedEnded {
          ...ProductProfileClosedEndFragment
        }
        # privateEquity {
        #   ...ProductProfilePrivateEquityFragment
        # }
      }
      ... on ClosedEndedRealAssets {
        closedEnded {
          ...ProductProfileClosedEndFragment
        }
        # realAssets{
        #   ...ProductProfileRealAssetsFragment
        # }
      }
      ... on OpenEndedRealAssets {
        openEnded {
          ...ProductProfileOpenEndFragment
        }
        # realAssets{
        #   ...ProductProfileRealAssetsFragment
        # }
      }
    }
    employeeRaceTypes: __type(name: "EmployeeRaceType") {
      enumValues {
        code: name
        value: description
      }
    }
    employeeGenderTypes: __type(name: "EmployeeGenderType") {
      enumValues {
        code: name
        value: description
      }
    }
  }
  ${PRODUCT_PROFILE_OPEN_END_FRAGMENT}
  ${PRODUCT_PROFILE_CLOSED_END_FRAGMENT}
  ${PRODUCT_PROFILE_TARGET_DATE_FRAGMENT}
  ${PLAN_PORTFOLIO_FRAGMENT}
`

export const PRODUCT_PROFILE_STRATEGY_QUERY = gql`
  query ProductProfileStrategy($id: Int!, $startDate: Date!, $endDate: Date!) {
    product(id: $id) {
      product {
        id
        name
        assetClass {
          id
          shortName
          fullName
          parent {
            id
            shortName
          }
        }
        currency {
          code
          value
        }
        activePassive
        inceptionDate
        primaryBenchmark {
          name
        }
        primaryGeographicRegion {
          value
        }
        productRelatedClientPlanExposure {
          clientAssets
          plan {
            id
            name
            client {
              id
            }
          }
          vehicle {
            vehicle {
              id: fundid
              fundid
              name
              category {
                code
                value
              }
            }
            ... on OpenEndedMutualFund {
              mutualFund {
                ...VehicleProfileMutualFragment
              }
            }
            ... on OpenEndedExchangeTradedFund {
              exchangeTradedFund {
                ...VehicleProfileExchangeFragment
              }
            }
            ... on OpenEndedPooledVehicle {
              pooledVehicle {
                ...VehicleProfilePoolFragment
              }
            }
            ... on OpenEndedVariableAnnuity {
              variableAnnuity {
                ...VehicleProfileAnnuityFragment
              }
            }
            ... on OpenEndedCollectiveInvestmentFundComposite {
              collectiveInvestmentFundComposite {
                ...VehicleProfileCollectiveFundCompositeFragment
              }
            }
            ... on OpenEndedSeparateAccount {
              separateAccount {
                ...VehicleProfileSeparateAccountFragment
              }
            }
            ... on ClosedEndedPrivateNonRegisteredMulitAssetClass {
              closedEndedVehicle {
                ...VehicleProfileClosedEndFragment
              }
            }
            ... on ClosedEndedPrivateNonRegisteredPrivateCredit {
              closedEndedVehicle {
                ...VehicleProfileClosedEndFragment
              }
            }
            ... on OpenEndedPooledVehiclePrivateEquity {
              pooledVehicle {
                ...VehicleProfilePoolFragment
              }
              privateEquityVehicle {
                ...VehicleProfilePrivateEquityFragment
              }
            }
            ... on ClosedEndedPrivateNonRegisteredPrivateEquity {
              closedEndedVehicle {
                ...VehicleProfileClosedEndFragment
              }
              privateEquityVehicle {
                ...VehicleProfilePrivateEquityFragment
              }
            }
            ... on OpenEndedPooledVehicleRealAssets {
              pooledVehicle {
                ...VehicleProfilePoolFragment
              }
              realAssetsVehicle {
                ...VehicleProfileRealAssetsFragment
              }
            }
            ... on ClosedEndedPrivateNonRegisteredRealAssets {
              closedEndedVehicle {
                ...VehicleProfileClosedEndFragment
              }
              realAssetsVehicle {
                ...VehicleProfileRealAssetsFragment
              }
            }
            ... on OpenEndedSeparateAccountRealAssets {
              separateAccount {
                ...VehicleProfileSeparateAccountFragment
              }
              realAssetsVehicle {
                ...VehicleProfileRealAssetsFragment
              }
            }
            ... on OpenEndedPrivateNonRegisteredHedgeFund {
              hedgeFundVehicle {
                ...VehicleProfileHedgeFundFragment
              }
            }
            ... on OpenEndedCollectiveInvestmentFundStableValueComposite {
              collectiveInvestmentFundComposite {
                ...VehicleProfileCollectiveFundCompositeFragment
              }
              stableValue {
                ...VehicleProfileStableFragment
              }
              stableValueCollectiveInvestmentFund {
                ...VehicleProfileStableCollectiveFragment
              }
            }
            ... on OpenEndedCollectiveInvestmentFundStableValue {
              collectiveInvestmentFund {
                ...VehicleProfileCollectiveFundFragment
              }
              stableValue {
                ...VehicleProfileStableFragment
              }
              stableValueCollectiveInvestmentFund {
                ...VehicleProfileStableCollectiveFragment
              }
            }
            ... on OpenEndedSeparateAccountStableValue {
              stableValue {
                ...VehicleProfileStableFragment
              }
              separateAccount {
                ...VehicleProfileSeparateAccountFragment
              }
              stableValueSeparateAccount {
                ...VehicleProfileStableSeparateAccountFragment
              }
            }
            ... on OpenEndedCollectiveInvestmentFund {
              collectiveInvestmentFund {
                ...VehicleProfileCollectiveFundFragment
              }
            }
            ... on OpenEndedPooledVehiclePrivateCredit {
              pooledVehicle {
                ...VehicleProfilePoolFragment
              }
            }
          }
        }
        hardCap
        vehicles {
          vehicle {
            id: fundid
            fundid
            name
            isRepresentative
            category {
              code
              value
            }
            status {
              code
              value
            }
            inceptionDate
            identifiers {
              ticker
              cusip
            }
            datedFees {
              date
              minimumSize
              minimumAnnualFee
            }
          }
          ... on OpenEndedMutualFund {
            #   openEndedVehicle{
            #     ...VehicleProfileOpenEndedFragment
            #   }
            mutualFund {
              ...VehicleProfileMutualFragment
            }
          }
          ... on OpenEndedExchangeTradedFund {
            #   openEndedVehicle{
            #     ...VehicleProfileOpenEndedFragment
            #   }
            exchangeTradedFund {
              ...VehicleProfileExchangeFragment
            }
          }
          ... on OpenEndedPooledVehicle {
            #   openEndedVehicle{
            #     ...VehicleProfileOpenEndedFragment
            #   }
            pooledVehicle {
              ...VehicleProfilePoolFragment
            }
          }
          ... on OpenEndedVariableAnnuity {
            #   openEndedVehicle{
            #     ...VehicleProfileOpenEndedFragment
            #   }
            variableAnnuity {
              ...VehicleProfileAnnuityFragment
            }
          }
          ... on OpenEndedCollectiveInvestmentFundComposite {
            #   openEndedVehicle{
            #     ...VehicleProfileOpenEndedFragment
            #   }
            collectiveInvestmentFundComposite {
              ...VehicleProfileCollectiveFundCompositeFragment
            }
          }
          ... on OpenEndedSeparateAccount {
            #   openEndedVehicle{
            #     ...VehicleProfileOpenEndedFragment
            #   }
            separateAccount {
              ...VehicleProfileSeparateAccountFragment
            }
          }
          ... on ClosedEndedPrivateNonRegisteredMulitAssetClass {
            closedEndedVehicle {
              ...VehicleProfileClosedEndFragment
            }
          }
          ... on ClosedEndedPrivateNonRegisteredPrivateCredit {
            closedEndedVehicle {
              ...VehicleProfileClosedEndFragment
              catchupFee {
                  date
                  catchupFee
              }
            }
            #   privateCreditVehicle{
            #     ...VehicleProfilePrivateCreditFragment
            #   }
          }
          ... on OpenEndedPooledVehiclePrivateEquity {
            #   openEndedVehicle{
            #     ...VehicleProfileOpenEndedFragment
            #   }
            pooledVehicle {
              ...VehicleProfilePoolFragment
            }
            privateEquityVehicle {
              ...VehicleProfilePrivateEquityFragment
            }
          }
          ... on ClosedEndedPrivateNonRegisteredPrivateEquity {
            closedEndedVehicle {
              ...VehicleProfileClosedEndFragment
              catchupFee {
                  date
                  catchupFee
              }
            }
            privateEquityVehicle {
              ...VehicleProfilePrivateEquityFragment
            }
          }
          ... on OpenEndedPooledVehicleRealAssets {
            # openEndedVehicle{
            #   ...VehicleProfileOpenEndedFragment
            # }
            pooledVehicle {
              ...VehicleProfilePoolFragment
            }
            realAssetsVehicle {
              ...VehicleProfileRealAssetsFragment
            }
          }
          ... on ClosedEndedPrivateNonRegisteredRealAssets {
            closedEndedVehicle {
              ...VehicleProfileClosedEndFragment
            }
            realAssetsVehicle {
              ...VehicleProfileRealAssetsFragment
            }
          }
          ... on OpenEndedSeparateAccountRealAssets {
            # openEndedVehicle{
            #   ...VehicleProfileOpenEndedFragment
            # }
            separateAccount {
              ...VehicleProfileSeparateAccountFragment
            }
            realAssetsVehicle {
              ...VehicleProfileRealAssetsFragment
            }
          }
          ... on OpenEndedPrivateNonRegisteredHedgeFund {
            # openEndedVehicle{
            #   ...VehicleProfileOpenEndedFragment
            # }
            hedgeFundVehicle {
              ...VehicleProfileHedgeFundFragment
            }
          }
          ... on OpenEndedCollectiveInvestmentFundStableValueComposite {
            # openEndedVehicle{
            #   ...VehicleProfileOpenEndedFragment
            # }
            collectiveInvestmentFundComposite {
              ...VehicleProfileCollectiveFundCompositeFragment
            }
            stableValue {
              ...VehicleProfileStableFragment
            }
            stableValueCollectiveInvestmentFund {
              ...VehicleProfileStableCollectiveFragment
            }
          }
          ... on OpenEndedCollectiveInvestmentFundStableValue {
            # openEndedVehicle{
            #   ...VehicleProfileOpenEndedFragment
            # }
            collectiveInvestmentFund {
              ...VehicleProfileCollectiveFundFragment
            }
            stableValue {
              ...VehicleProfileStableFragment
            }
            stableValueCollectiveInvestmentFund {
              ...VehicleProfileStableCollectiveFragment
            }
          }
          ... on OpenEndedSeparateAccountStableValue {
            # openEndedVehicle{
            #   ...VehicleProfileOpenEndedFragment
            # }
            stableValue {
              ...VehicleProfileStableFragment
            }
            separateAccount {
              ...VehicleProfileSeparateAccountFragment
            }
            stableValueSeparateAccount {
              ...VehicleProfileStableSeparateAccountFragment
            }
          }
          ... on OpenEndedCollectiveInvestmentFund {
            #   openEndedVehicle{
            #     ...VehicleProfileOpenEndedFragment
            #   }
            collectiveInvestmentFund {
              ...VehicleProfileCollectiveFundFragment
            }
          }
          ... on OpenEndedPooledVehiclePrivateCredit {
            #   openEndedVehicle{
            #     ...VehicleProfileOpenEndedFragment
            #   }
            pooledVehicle {
              ...VehicleProfilePoolFragment
            }
            #   privateCreditVehicle{
            #     ...VehicleProfilePrivateCreditFragment
            #   }
          }
          # ... on OtherVehicle{

          # }
        }
        productRelatedClientPortfolioExposure {
          clientAssets
          vehicle {
            vehicle {
              id: fundid
              fundid
              name
              category {
                code
                value
              }
              status {
                code
              }
            }
            ... on OpenEndedMutualFund {
              mutualFund {
                ...VehicleProfileMutualFragment
              }
            }
            ... on OpenEndedExchangeTradedFund {
              exchangeTradedFund {
                ...VehicleProfileExchangeFragment
              }
            }
            ... on OpenEndedPooledVehicle {
              pooledVehicle {
                ...VehicleProfilePoolFragment
              }
            }
            ... on OpenEndedVariableAnnuity {
              variableAnnuity {
                ...VehicleProfileAnnuityFragment
              }
            }
            ... on OpenEndedCollectiveInvestmentFundComposite {
              collectiveInvestmentFundComposite {
                ...VehicleProfileCollectiveFundCompositeFragment
              }
            }
            ... on OpenEndedSeparateAccount {
              separateAccount {
                ...VehicleProfileSeparateAccountFragment
              }
            }
            ... on ClosedEndedPrivateNonRegisteredMulitAssetClass {
              closedEndedVehicle {
                ...VehicleProfileClosedEndFragment
              }
            }
            ... on ClosedEndedPrivateNonRegisteredPrivateCredit {
              closedEndedVehicle {
                ...VehicleProfileClosedEndFragment
              }
            }
            ... on OpenEndedPooledVehiclePrivateEquity {
              pooledVehicle {
                ...VehicleProfilePoolFragment
              }
              privateEquityVehicle {
                ...VehicleProfilePrivateEquityFragment
              }
            }
            ... on ClosedEndedPrivateNonRegisteredPrivateEquity {
              closedEndedVehicle {
                ...VehicleProfileClosedEndFragment
              }
              privateEquityVehicle {
                ...VehicleProfilePrivateEquityFragment
              }
            }
            ... on OpenEndedPooledVehicleRealAssets {
              pooledVehicle {
                ...VehicleProfilePoolFragment
              }
              realAssetsVehicle {
                ...VehicleProfileRealAssetsFragment
              }
            }
            ... on ClosedEndedPrivateNonRegisteredRealAssets {
              closedEndedVehicle {
                ...VehicleProfileClosedEndFragment
              }
              realAssetsVehicle {
                ...VehicleProfileRealAssetsFragment
              }
            }
            ... on OpenEndedSeparateAccountRealAssets {
              separateAccount {
                ...VehicleProfileSeparateAccountFragment
              }
              realAssetsVehicle {
                ...VehicleProfileRealAssetsFragment
              }
            }
            ... on OpenEndedPrivateNonRegisteredHedgeFund {
              hedgeFundVehicle {
                ...VehicleProfileHedgeFundFragment
              }
            }
            ... on OpenEndedCollectiveInvestmentFundStableValueComposite {
              collectiveInvestmentFundComposite {
                ...VehicleProfileCollectiveFundCompositeFragment
              }
              stableValue {
                ...VehicleProfileStableFragment
              }
              stableValueCollectiveInvestmentFund {
                ...VehicleProfileStableCollectiveFragment
              }
            }
            ... on OpenEndedCollectiveInvestmentFundStableValue {
              collectiveInvestmentFund {
                ...VehicleProfileCollectiveFundFragment
              }
              stableValue {
                ...VehicleProfileStableFragment
              }
              stableValueCollectiveInvestmentFund {
                ...VehicleProfileStableCollectiveFragment
              }
            }
            ... on OpenEndedSeparateAccountStableValue {
              stableValue {
                ...VehicleProfileStableFragment
              }
              separateAccount {
                ...VehicleProfileSeparateAccountFragment
              }
              stableValueSeparateAccount {
                ...VehicleProfileStableSeparateAccountFragment
              }
            }
            ... on OpenEndedCollectiveInvestmentFund {
              collectiveInvestmentFund {
                ...VehicleProfileCollectiveFundFragment
              }
            }
            ... on OpenEndedPooledVehiclePrivateCredit {
              pooledVehicle {
                ...VehicleProfilePoolFragment
              }
            }
          }
          portfolio {
            endAssetDate
            id
            clientSpecificDataCollectionFields {
              questionnaireFeesRequired
            }
            plan {
              id
              name
              client {
                id
              }
            }
            relatedVehicle {
              vehicle {
                id: fundid
                fundid
              }
            }
            datedFees {
              date
              totalExpense
              annualizedSubAdvisedPctOfApplicableSubadvisedAssets
              annualizedWrapPctOfApplicableWrappedAssets
            }
            feeAtAum50: feeAtAum(filters: { aum: 50000000 }) {
              date
              fee
            }
            feeAtAum100: feeAtAum(filters: { aum: 100000000 }) {
              date
              fee
            }
          }
        }
      }
      ... on OpenEndedEquity {
        equity {
          ...ProductProfileEquityFragment
        }
        openEnded {
          ...ProductProfileOpenEndFragment
        }
      }
      ... on OpenEndedPassiveEquity {
        equity {
          ...ProductProfileEquityFragment
        }
        openEnded {
          ...ProductProfileOpenEndFragment
        }
        # passiveProduct{
        #   ...ProductProfilePassiveFragment
        # }
      }
      ... on OpenEndedFixedIncome {
        openEnded {
          ...ProductProfileOpenEndFragment
        }
        # fixedIncome{
        #   ...ProductProfileFixedIncomeFragment
        # }
      }
      ... on OpenEndedPassiveFixedIncome {
        openEnded {
          ...ProductProfileOpenEndFragment
        }
        # passiveProduct{
        #   ...ProductProfilePassiveFragment
        # }
        # fixedIncome{
        #   ...ProductProfileFixedIncomeFragment
        # }
      }
      ... on OpenEndedHedgeFund {
        openEnded {
          ...ProductProfileOpenEndFragment
        }
        # hedgeFund{
        #   ...ProductProfileHedgeFragment
        # }
      }
      ... on ClosedEndedHedgeFund {
        closedEnded {
          ...ProductProfileClosedEndFragment
        }
        # hedgeFund{
        #   ...ProductProfileHedgeFragment
        # }
      }
      ... on OpenEndedMAC {
        openEnded {
          ...ProductProfileOpenEndFragment
        }
        equity {
          ...ProductProfileEquityFragment
        }
        # fixedIncome{
        #   ...ProductProfileFixedIncomeFragment
        # }
        # multiAssetClass{
        #   ...ProductProfileMACFragment
        # }
      }
      ... on OpenEndedMAC {
        openEnded {
          ...ProductProfileOpenEndFragment
        }
        equity {
          ...ProductProfileEquityFragment
        }
        # fixedIncome{
        #   ...ProductProfileFixedIncomeFragment
        # }
        # multiAssetClass{
        #   ...ProductProfileMACFragment
        # }
        # passiveProduct{
        #   ...ProductProfilePassiveFragment
        # }
      }
      ... on OpenEndedTargetDate {
        openEnded {
          ...ProductProfileOpenEndFragment
        }
        targetDate {
          ...ProductProfileTargetDateFragment
        }
        equity {
          ...ProductProfileEquityFragment
        }
        # fixedIncome{
        #   ...ProductProfileFixedIncomeFragment
        # }
      }
      ... on ClosedEndedPrivateCredit {
        closedEnded {
          ...ProductProfileClosedEndFragment
        }
        privateCredit {
          ...ProductProfilePrivateCreditFragment
        }
      }
      ... on ClosedEndedPrivateEquity {
        closedEnded {
          ...ProductProfileClosedEndFragment
        }
        privateEquity {
          ...ProductProfilePrivateEquityFragment
        }
      }
      ... on ClosedEndedRealAssets {
        closedEnded {
          ...ProductProfileClosedEndFragment
        }
        realAssets {
          ...ProductProfileRealAssetsFragment
        }
      }
      ... on OpenEndedRealAssets {
        openEnded {
          ...ProductProfileOpenEndFragment
        }
        realAssets {
          ...ProductProfileRealAssetsFragment
        }
      }
    }
  }
  ${PRODUCT_PROFILE_EQUITY_FRAGMENT}
  ${PRODUCT_PROFILE_PRIVATE_EQUITY_FRAGMENT}
  ${PRODUCT_PROFILE_PRIVATE_CREDIT_FRAGMENT}
  ${PRODUCT_PROFILE_CLOSED_END_FRAGMENT}
  ${PRODUCT_PROFILE_REAL_ASSETS_FRAGMENT}
  ${PRODUCT_PROFILE_TARGET_DATE_FRAGMENT}
  ${PRODUCT_PROFILE_OPEN_END_FRAGMENT}
  ${VEHICLE_PROFILE_CLOSED_END_FRAGMENT}
  ${VEHICLE_PROFILE_PRIVATE_EQUITY_FRAGMENT}
  ${VEHICLE_PROFILE_HEDGE_FUND_FRAGMENT}
  ${VEHICLE_PROFILE_STABLE_FRAGMENT}
  ${VEHICLE_PROFILE_REAL_ASSETS_FRAGMENT}
  ${VEHICLE_PROFILE_MUTUAL_FRAGMENT}
  ${VEHICLE_PROFILE_EXCHANGE_FRAGMENT}
  ${VEHICLE_PROFILE_POOL_FRAGMENT}
  ${VEHICLE_PROFILE_ANNUITY_FRAGMENT}
  ${VEHICLE_PROFILE_COLLECTIVE_FUND_COMPOSITE_FRAGMENT}
  ${VEHICLE_PROFILE_SEPARATE_ACCOUNT_FRAGMENT}
  ${VEHICLE_PROFILE_COLLECTIVE_FUND_FRAGMENT}
  ${VEHICLE_PROFILE_STABLE_SEPARATE_ACCOUNT_FRAGMENT}
  ${VEHICLE_PROFILE_STABLE_COLLECTIVE_FRAGMENT}
`

export const PRODUCT_MANAGEMENT_QUERY = gql`
  query ProductManagement($id: Int!) {
    product(id: $id) {
      ...ProductManagementFragment
      ...ProductManagementPassiveFragment
    }
    assetClassMaps {
      investmentManagementStrategyCodeAssetClassMap {
        code
        assetMixNum
      }
    }
    investmentCodes: __type(name: "InvestmentManagementStrategyCode") {
      enumValues {
        code: name
        value: description
      }
    }
  }
  ${PRODUCT_MANAGEMENT_FRAGMENT}
  ${PRODUCT_MANAGEMENT_PASSIVE_FRAGMENT}
`

export const DELETE_PRODUCT_MANAGEMENT_PERFORMANCE_COMPOSITE_DISPERSION = gql`
  mutation deletePerformanceCompositeDispersion(
    $input: DeleteProductManagementPerformanceCompositeDispersionInput!
  ) {
    deleteProductManagementPerformanceCompositeDispersion(input: $input) {
      status
      message
    }
  }
`

export const UPDATE_PRODUCT_MANAGEMENT_MUTATION = gql`
  mutation UpdateProductManagement($input: UpdateProductManagementInput!) {
    updateProductManagement(input: $input) {
      product {
        ...ProductManagementFragment
        ...ProductManagementPassiveFragment
      }
    }
  }
  ${PRODUCT_MANAGEMENT_FRAGMENT}
  ${PRODUCT_MANAGEMENT_PASSIVE_FRAGMENT}
`

export const PRODUCT_ESG_QUERY = gql`
  query ProductEsg($id: Int!) {
    product(id: $id) {
      ...ProductESGFragment
    }
  }
  ${PRODUCT_ESG_FRAGMENT}
`

export const UPDATE_PRODUCT_ESG_MUTATION = gql`
  mutation UpdateProductEsg($input: UpdateProductInput!) {
    updateProduct(input: $input) {
      product {
        ...ProductESGFragment
      }
    }
  }
  ${PRODUCT_ESG_FRAGMENT}
`

export const PRODUCT_SUMMARY_QUERY = gql`
  query ProductSummary($id: Int!) {
    product(id: $id) {
      ...ProductSummaryFragment
    }
  }
  ${PRODUCT_SUMMARY_FRAGMENT}
`

export const UPDATE_PRODUCT_SUMMARY_MUTATION = gql`
  mutation UpdateProductOverviewSummary(
    $input: UpdateProductOverviewSummaryInput!
  ) {
    updateProductOverviewSummary(input: $input) {
      product {
        ...ProductSummaryFragment
      }
    }
  }
  ${PRODUCT_SUMMARY_FRAGMENT}
`

export const DELETE_MATERIAL_CHANGES_TO_PRODUCT = gql`
  mutation DeleteProductMaterialChanges(
    $input: DeleteMaterialChangesToProductInput!
  ) {
    deleteMaterialChangesToProduct(input: $input) {
      status
      message
    }
  }
`

export const DELETE_ASSOCIATE_SYMBOLS_TO_PRODUCT = gql`
  mutation DeleteAssociatedSymbols($input: DeleteAssociatedSymbolsInput!) {
    deleteAssociatedSymbols(input: $input) {
      status
      message
    }
  }
`

export const DELETE_GEORAPHIC_REGION_CODES = gql`
  mutation DeleteGeographicRegionCodes(
    $input: DeleteGeographicRegionCodesInput!
  ) {
    deleteGeographicRegionCodes(input: $input) {
      status
      message
    }
  }
`

export const DELETE_US_REGION_CODES = gql`
  mutation DeleteUSRegionCodes($input: DeleteUSRegionCodesInput!) {
    deleteUSRegionCodes(input: $input) {
      status
      message
    }
  }
`

export const DELETE_PRODUCT_SECTORS = gql`
  mutation DeleteProductSectors($input: DeleteProductSectorsInput!) {
    deleteProductSectors(input: $input) {
      status
      message
    }
  }
`

export const PRODUCT_EMPLOYEES_QUERY = gql`
  query ProductEmployees($id: Int!, $startDate: Date, $endDate: Date) {
    product(id: $id) {
      ...ProductEmployeesFragment
    }
  }
  ${PRODUCT_EMPLOYEES_FRAGMENT}
`

export const UPDATE_PRODUCT_EMPLOYEES_MUTATION = gql`
  mutation UpdateProductOverviewEmployees(
    $input: UpdateProductEmployeeBreakdownsInput!
    $startDate: Date
    $endDate: Date
  ) {
    updateProductEmployeeBreakdowns(input: $input) {
      product {
        ...ProductEmployeesFragment
      }
    }
  }
  ${PRODUCT_EMPLOYEES_FRAGMENT}
`

export const UPDATE_PRODUCT_EMPLOYEES_BREAKDOWN_DETAIL_MUTATION = gql`
  mutation UpdateProductOverviewEmployeesBreakdownDetail(
    $input: EmployeeBreakdownDetailInput!
    $startDate: Date
    $endDate: Date
  ) {
    updateProductEmployeeBreakdownDetail(input: $input) {
      product {
        ...ProductEmployeesFragment
      }
    }
  }
  ${PRODUCT_EMPLOYEES_FRAGMENT}
`

export const UPDATE_PRODUCT_EMPLOYEES_BREAKDOWN_OTHER_MINORITIES_MUTATION = gql`
  mutation UpdateProductOverviewEmployeesBreakdownOtherMinorities(
    $input: EmployeeBreakdownByOtherMinorityInput!
    $startDate: Date
    $endDate: Date
  ) {
    updateProductEmployeeBreakdownByOtherMinorities(input: $input) {
      product {
        ...ProductEmployeesFragment
      }
    }
  }
  ${PRODUCT_EMPLOYEES_FRAGMENT}
`

export const PRODUCT_GP_COMMITMENT_QUERY = gql`
  query ProductGPCommitments($id: Int!) {
    product(id: $id) {
      ...ProductGPCommitmentsFragment
    }
  }
  ${PRODUCT_GP_COMMITMENTS_FRAGMENT}
`

export const PRODUCT_GP_COMMITMENT_MUTATION = gql`
  mutation UpdateProductGPCommitments(
    $input: UpdateGeneralPartnerCommitmentInput!
  ) {
    updateGeneralPartnerCommitments(input: $input) {
      product {
        ...ProductGPCommitmentsFragment
      }
    }
  }
  ${PRODUCT_GP_COMMITMENTS_FRAGMENT}
`

export const CREATE_PRODUCT_GP_COMMITMENTS_MUTATION = gql`
  mutation CreateProductGPCommitments(
    $input: CreateGeneralPartnerCommitmentInput!
  ) {
    createGeneralPartnerCommitments(input: $input) {
      product {
        ...ProductGPCommitmentsFragment
      }
    }
  }
`

export const DELETE_PRODUCT_GP_COMMITMENT_LISTS_MUTATION = gql`
  mutation DeleteProductGPLists(
    $input: DeleteGeneralPartnerCommitmentsListInput!
  ) {
    deleteGeneralPartnerCommitmentsList(input: $input) {
      status
      message
    }
  }
`

export const DELETE_PRODUCT_INVESTOR_ROSTER_LISTS_MUTATION = gql`
  mutation DeleteProductGpInvestorLists(
    $input: DeleteInvestorRostersListInput!
  ) {
    deleteInvestorRostersList(input: $input) {
      status
      message
    }
  }
`

export const PRODUCT_GUIDELINES_QUERY = gql`
  query ProductGuidelines($id: Int!) {
    product(id: $id) {
      ...ProductGuidelineFragment
    }
  }
  ${PRODUCT_GUIDELINES_FRAGMENT}
`
export const PRODUCT_GUIDELINES_MUTATION = gql`
  mutation UpdateProductGuidelines($input: UpdateProductGuidelinesInput!) {
    updateProductGuidelines(input: $input) {
      product {
        ...ProductGuidelineFragment
      }
    }
  }
  ${PRODUCT_GUIDELINES_FRAGMENT}
`

export const PRODUCT_PROCESS_QUERY = gql`
  query ProductProcess($id: Int!) {
    product(id: $id) {
      ...ProductProcessFragment
    }
  }
  ${PRODUCT_PROCESS_FRAGMENT}
`
export const PRODUCT_PROCESS_MUTATION = gql`
  mutation UpdateProductProcess($input: UpdateProductProcessInput!) {
    updateProductProcess(input: $input) {
      product {
        ...ProductProcessFragment
      }
    }
  }
  ${PRODUCT_PROCESS_FRAGMENT}
`

export const PRODUCT_ASSETS_ACCOUNTS_LARGEST_FRAGMENT = gql`
  fragment ProductAssetsAccountsLargestFragment on ProductLargestAccounts {
    quarterEndDate
    accounts {
      rank
      type {
        code
        value
      }
      size
    }
  }
`

export const PRODUCT_ASSETS_BY_ACCOUNTS_QUERY = gql`
  query ProductAssetsByAccountsQuery(
    $id: Int!
    $startDate: Date
    $endDate: Date
  ) {
    product(id: $id) {
      product {
        id
        name
        largestAccounts(startDate: $startDate, endDate: $endDate) {
          ...ProductAssetsAccountsLargestFragment
        }
        accountTurnover(startDate: $startDate, endDate: $endDate) {
          quarterEndDate
          gained {
            accounts
            percentChange
            assets
          }
          lost {
            accounts
            percentChange
            assets
          }
        }
        accountRanges(startDate: $startDate, endDate: $endDate) {
          quarterEndDate
          lessThanOneMillion {
            assetsInRange
            accountsInRange
          }
          oneMillionToTenMillion {
            assetsInRange
            accountsInRange
          }
          tenMillionToOneHundredMillion {
            assetsInRange
            accountsInRange
          }
          oneHundredMillionToFiveHundredMillion {
            assetsInRange
            accountsInRange
          }
          moreThanFiveHundredMillion {
            assetsInRange
            accountsInRange
          }
        }
      }
    }
  }
  ${PRODUCT_ASSETS_ACCOUNTS_LARGEST_FRAGMENT}
`

export const UPDATE_PRODUCT_ASSETS_BY_ACCOUNTS_SINGLE = gql`
  mutation UpdateProductAssetsByAccountsSingle(
    $input: UpdateProductAssetsByAccountSizeSingleInput!
    $startDate: Date
    $endDate: Date
  ) {
    product: updateProductAssetsByAccountSizeSingle(input: $input) {
      product {
        product {
          id
          largestAccounts(startDate: $startDate, endDate: $endDate) {
            quarterEndDate
            accounts {
              rank
              type {
                code
                value
              }
              size
            }
          }
          accountTurnover(startDate: $startDate, endDate: $endDate) {
            quarterEndDate
            gained {
              accounts
              percentChange
              assets
            }
            lost {
              accounts
              percentChange
              assets
            }
          }
          accountRanges(startDate: $startDate, endDate: $endDate) {
            quarterEndDate
            lessThanOneMillion {
              assetsInRange
              accountsInRange
            }
            oneMillionToTenMillion {
              assetsInRange
              accountsInRange
            }
            tenMillionToOneHundredMillion {
              assetsInRange
              accountsInRange
            }
            oneHundredMillionToFiveHundredMillion {
              assetsInRange
              accountsInRange
            }
            moreThanFiveHundredMillion {
              assetsInRange
              accountsInRange
            }
          }
        }
      }
    }
  }
`

export const UPDATE_PRODUCT_ASSETS_BY_ACCOUNTS_HISTORICAL = gql`
  mutation UpdateProductAssetsByAccountsHistorical(
    $input: UpdateProductAssetsByAccountSizeHistoricalInput!
    $startDate: Date
    $endDate: Date
  ) {
    product: updateProductAssetsByAccountSizeHistorical(input: $input) {
      product {
        product {
          id
          largestAccounts(startDate: $startDate, endDate: $endDate) {
            quarterEndDate
            accounts {
              rank
              type {
                code
                value
              }
              size
            }
          }
          accountTurnover(startDate: $startDate, endDate: $endDate) {
            quarterEndDate
            gained {
              accounts
              percentChange
              assets
            }
            lost {
              accounts
              percentChange
              assets
            }
          }
          accountRanges(startDate: $startDate, endDate: $endDate) {
            quarterEndDate
            lessThanOneMillion {
              assetsInRange
              accountsInRange
            }
            oneMillionToTenMillion {
              assetsInRange
              accountsInRange
            }
            tenMillionToOneHundredMillion {
              assetsInRange
              accountsInRange
            }
            oneHundredMillionToFiveHundredMillion {
              assetsInRange
              accountsInRange
            }
            moreThanFiveHundredMillion {
              assetsInRange
              accountsInRange
            }
          }
        }
      }
    }
  }
`
export const PRODUCT_BENCHMARK_OPTIONS = gql`
  query ProductBenchmarksOptions($asset_mix_num: Int!) {
    productBenchmarks(assetClass: $asset_mix_num) {
      code: benchmark
      value: name
      assetMixNum
    }
  }
`

export const PRODUCT_ASSETS_BY_CLIENT_TYPE = gql`
  query ProductAssetsByClientType(
    $id: Int!
    $startDate: Date
    $endDate: Date
    $clientType: ClientType
    $categoryType: CategoryType
  ) {
    product(id: $id) {
      ...ProductAssetsByClientTypeFragment
    }
  }
  ${PRODUCT_ASSETS_CLIENT_TYPE_FRAGMENT}
`

export const UPDATE_PRODUCT_ASSETS_BY_CLIENT_TYPE_SINGLE = gql`
  mutation UpdateProductAssetsByClientTypeSingle(
    $input: UpdateProductAssetsByClientSingleInput!
    $startDate: Date
    $endDate: Date
    $clientType: ClientType
    $categoryType: CategoryType
  ) {
    product: updateProductAssetsByClientSingle(input: $input) {
      product {
        ...ProductAssetsByClientTypeFragment
      }
    }
  }
`
export const UPDATE_PRODUCT_ASSETS_BY_CLIENT_TYPE_HISTORIC_ASSETS = gql`
  mutation UpdateProductAssetsByClientTypeHistoricAssets(
    $input: UpdateProductAssetsByClientHistoricalAssetInput!
    $startDate: Date
    $endDate: Date
    $clientType: ClientType
    $categoryType: CategoryType
  ) {
    product: updateProductAssetsByClientHistoricalAssets(input: $input) {
      product {
        ...ProductAssetsByClientTypeFragment
      }
    }
  }
`

export const UPDATE_PRODUCT_ASSETS_BY_CLIENT_TYPE_HISTORIC_ACCOUNTS = gql`
  mutation UpdateProductAssetsByClientTypeHistoricAccounts(
    $input: UpdateProductAssetsByClientHistoricalAccountInput!
    $startDate: Date
    $endDate: Date
    $clientType: ClientType
    $categoryType: CategoryType
  ) {
    product: updateProductAssetsByClientHistoricalAccount(input: $input) {
      product {
        ...ProductAssetsByClientTypeFragment
      }
    }
  }
`

export const PRODUCT_ASSETS_BY_REGION = gql`
  query ProductAssetsByRegion(
    $id: Int!
    $startDate: Date
    $endDate: Date
    $regionType: RegionType
  ) {
    product(id: $id) {
      ...ProductAssetsByRegionFragment
    }
  }
  ${PRODUCT_ASSETS_REGION_FRAGMENT}
`

export const UPDATE_PRODUCT_ASSETS_BY_REGION_SINGLE = gql`
  mutation UpdateProductAssetsByRegionSingle(
    $input: UpdateProductAssetsByRegionSingleInput!
    $startDate: Date
    $endDate: Date
    $regionType: RegionType
  ) {
    product: updateProductAssetsByRegionSingle(input: $input) {
      product {
        ...ProductAssetsByRegionFragment
      }
    }
  }
`
export const UPDATE_PRODUCT_ASSETS_BY_REGION_HISTORIC_ASSETS = gql`
  mutation UpdateProductAssetsByRegionHistoricAssets(
    $input: UpdateProductAssetsByRegionHistoricalAssetInput!
    $startDate: Date
    $endDate: Date
    $regionType: RegionType
  ) {
    product: updateProductAssetsByRegionHistoricalAssets(input: $input) {
      product {
        ...ProductAssetsByRegionFragment
      }
    }
  }
`

export const UPDATE_PRODUCT_ASSETS_BY_REGION_HISTORIC_ACCOUNTS = gql`
  mutation UpdateProductAssetsByRegionHistoricAccounts(
    $input: UpdateProductAssetsByRegionHistoricalAccountInput!
    $startDate: Date
    $endDate: Date
    $regionType: RegionType
  ) {
    product: updateProductAssetsByRegionHistoricalAccount(input: $input) {
      product {
        ...ProductAssetsByRegionFragment
      }
    }
  }
`

export const PRODUCT_SUMMARY_OPTIONS_BY_ASSETCLASSMAPS = gql`
  query ProductSummaryOptionsByAssetClassMaps {
    assetClassMaps {
      targetInvestmentStyleCodeAssetClassMap {
        code
        assetMixNum
      }
      sectorByChildCodeAssetClassMap {
        code
        assetMixNum
      }
      geographicRegionCodeAssetClassMap {
        code
        assetMixNum
      }
    }
  }
`

export const PRODUCT_PROCESS_OPTIONS_BY_ASSETCLASSMAPS = gql`
  query ProductProcessOptionsByAssetClassMaps {
    assetClassMaps {
      productNarrativeTypeCodeAssetClassMap {
        code
        assetMixNum
      }
    }
  }
`

export const PRODUCT_PEOPLE_QUERY = gql`
  query ProductPeople($id: Int!) {
    product(id: $id) {
      product {
        id
        manager {
          id
          employees {
            ...PersonSummaryFragment
          }
          products {
            product {
              id
              name
              inactive
            }
          }
          managerTypes {
            code
          }
        }
      }
    }
  }
  ${PERSON_SUMMARY_FRAGMENT}
`

export const EMPLOYEE_JOB_TYPE_ASSET_QUERY = gql`
  query EmployeeJobTypeAsset {
    assetClassMaps {
      employeeJobTypeAssetClassMap {
        code
        assetMixNum
      }
    }
  }
`

export const PRODUCT_VEHICLES_QUERY = gql`
  query ProductVehicles($id: Int!) {
    product(id: $id) {
      __typename
      product {
        id
        structure {
          code
        }
        vehicles {
          vehicle {
            __typename
            id: fundid
            name
            inceptionDate
            status {
              code
              value
            }
            type {
              code
              value
            }
            category {
              code
              value
            }
            identifiers {
              ticker
              cusip
            }
            relatedClientPortfolioManagerDataCollect {
              id
              name
              client {
                id
                shortName
                name
              }
              relatedVehicle {
                vehicle {
                  id: fundid
                  name
                  category {
                    code
                    value
                  }
                  type {
                    code
                    value
                  }
                }
              }
              clientSpecificDataCollectionFields {
                nameOverride
                questionnaireCharacteristicsRequired
                questionnaireHoldingsRequired
                questionnaireCashFlowRequired
                questionnaireSummaryCashFlowRequired
                questionnaireFeesRequired
              }
            }
            vehicleDataCollection {
              performanceNeeded
              vehicleInfoNeeded
            }
          }
          ... on OpenEndedVehicle {
            openEndedVehicle {
              latestAssetsUnderManagement
            }
          }
        }
      }
    }
  }
`

export const PRODUCT_BY_VEHICLES_QUERY = gql`
  query ProductAssetsByVehicle($id: Int!, $startDate: Date, $endDate: Date) {
    product(id: $id) {
      ...ProductAssetsByVehicleFragment
    }
  }
  ${PRODUCT_ASSETS_VEHICLES_FRAGMENT}
`

export const PRODUCT_BY_VEHICLES_SINGLE_UPDATE = gql`
  mutation UpdateProductAssetsByVehicleSingle(
    $input: UpdateProductAssetsByVehicleSingleInput!
    $startDate: Date
    $endDate: Date
  ) {
    product: updateProductAssetsByVehicleSingle(input: $input) {
      product {
        ...ProductAssetsByVehicleFragment
      }
    }
  }
`

export const PRODUCT_BY_VEHICLES_HISTORIC_UPDATE = gql`
  mutation UpdateProductAssetsByVehicleHistorical(
    $input: UpdateProductAssetsByVehicleHistoricalInput!
    $startDate: Date
    $endDate: Date
  ) {
    product: updateProductAssetsByVehicleHistorical(input: $input) {
      product {
        ...ProductAssetsByVehicleFragment
      }
    }
  }
`

export const PRODUCT_CHARACTERISTICS_QUERY = gql`
  query ProductCharacteristics($id: Int!) {
    product(id: $id) {
      ...ProductCharacteristicsFragment
    }
  }
  ${PRODUCT_CHARACTERISTICS_FRAGMENT}
`

export const HOLDING_TEMPLATE_URL_QUERY = gql`
  query holdingTemplateSignedUrl {
    holdingExcelTemplateSignedUrl
    holdingTextTemplateSignedUrl
  }
`

export const HOLDING_UPLOAD_URLS_QUERY = gql`
  query holdingUploadSignedUrls(
    $id: HoldingKey!
    $quarterDate: Date!
    $filename: String!
    $filetype: String!
  ) {
    holdingUploadSignedUrls(
      id: $id
      quarterDate: $quarterDate
      filename: $filename
      filetype: $filetype
    ) {
      portfolioId
      quarterDate
      url
      uploadSignedUrl
      downloadSignedUrl
    }
  }
`

export const NOTIFY_HOLDING_MUTATION = gql`
  mutation notifyUpload($input: NotifyUploadInput!) {
    notifyUpload(input: $input) {
      status
      message
    }
  }
`

export const PRODUCT_WRITE_UPS_QUERY = gql`
  query ProductWriteUps($id: Int!, $filters: ManagerNoteFilters) {
    product(id: $id) {
      product {
        id
        writeUps {
          facts {
            ...FactFragment
          }
          opinions {
            vehicle {
              code
              value
            }
            opinions {
              ...OpinionFragment
            }
          }
        }
        notes(filters: $filters) {
          ...ManagerNoteFragment
        }
      }
    }
  }
  ${FACT_FRAGMENT}
  ${OPINION_FRAGMENT}
  ${MANAGER_NOTE_FRAGMENT}
`

export const GET_PRODUCT_WRITEUPS_URL = gql`
  query DownloadProductWriteups($args: DownloadProductWriteupsArgs!) {
    download: downloadProductWriteups(args: $args) {
      url
    }
  }
`

export const PRODUCT_INTERACTIONS_QUERY = gql`
  query ProductInteractions($id: Int!) {
    product(id: $id) {
      product {
        id
        interactions {
          ...InteractionSidebarFragment
        }
      }
    }
  }
  ${INTERACTION_SIDEBAR_FRAGMENT}
`
export const DELETE_PRODUCT_ESG_OPTION_EXCLUSIONS_MUTATION = gql`
  mutation DeleteProductEsgOptionExclusions(
    $input: DeleteEsgOptionExclusionInput!
  ) {
    deleteEsgOptionExclusions(input: $input) {
      status
      message
    }
  }
`

export const DELETE_PRODUCT_ESG_PRODUCT_VENDORS_MUTATION = gql`
  mutation DeleteProductEsgProductVendors(
    $input: DeleteEsgProductVendorInput!
  ) {
    deleteEsgProductVendors(input: $input) {
      status
      message
    }
  }
`

export const DELETE_PRODUCT_ESG_PRODUCT_COMPONENTS_MUTATION = gql`
  mutation DeleteProductEsgProductComponents(
    $input: DeleteEsgProductComponentInput!
  ) {
    deleteEsgProductComponents(input: $input) {
      status
      message
    }
  }
`

export const PRODUCT_OVERVIEW_OUTLOOK_QUERY = gql`
  query ProductOverviewOutlook($id: Int!, $startDate: Date, $endDate: Date) {
    product(id: $id) {
      ...ProductOverviewOutlookFragment
    }
  }
  ${PRODUCT_OVERVIEW_OUTLOOK_FRAGMENT}
`

export const PRODUCT_OVERVIEW_OUTLOOK_MUTATION = gql`
  mutation UpdateProductOverviewOutlook(
    $input: UpdateProductInput!
    $startDate: Date
    $endDate: Date
  ) {
    product: updateProduct(input: $input) {
      product {
        ...ProductOverviewOutlookFragment
      }
    }
  }
  ${PRODUCT_OVERVIEW_OUTLOOK_FRAGMENT}
`

export const PRODUCT_DOCUMENTS_QUERY = gql`
  query ProductDocuments($id: Int!) {
    product(id: $id) {
      product {
        id
        documents {
          ...FileBasicInfoFragment
        }
      }
    }
  }
  ${FILE_BASIC_INFO_FRAGMENT}
`

export const GET_PRODUCT_FACT_SHEET_URL = gql`
  query DownloadProductFactSheet($id: Int!) {
    download: downloadProductFactSheet(id: $id) {
      url
    }
  }
`

export const PRODUCT_ANALYSIS_COMPARISONS_QUERY = gql`
  query ProductAnalysisComparisons($id: Int!, $liveView: Boolean!, $draftView: Boolean!) {
    product(id: $id) {
      product {
        id
        name
        layout {
          ...ClientPortfolioDetailLayoutFragment
        }
      }
    }
  }
  ${CLIENT_PORTFOLIO_DETAIL_LAYOUT_FRAGMENT}
`
