import { GridApi, GridReadyEvent } from "@ag-grid-community/core"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import classnames from "classnames"
import _ from "lodash"
import moment from "moment"
import React, { useCallback, useContext, useEffect, useState } from "react"
import { RouteComponentProps, match } from "react-router-dom"
import { Button, ButtonDropdown, Col, Container, DropdownMenu, DropdownToggle, Form, FormGroup, Input, ListGroup, ListGroupItem, Row, UncontrolledAlert } from "reactstrap"
import Auth from "../../Auth/Auth"
import { EditButtonContext } from "../../Context/EditButtonContext"
import { TemporaryAlertContext } from "../../Context/TemporaryAlertContext"
import {
  ClientPortfolioBasicFragment,
  ClientPortfolioDefinitionFragment,
  CompositeMemberInputItem,
  PlanPortfoliosListQuery,
  useMeQuery,
  usePlanPortfoliosListQuery,
  useUpdateClientPortfolioDefinitionMutation,
} from "../../__generated__/graphql"
import { PlanClientPortfolioListColumnDef } from "../../helpers/columnDefs"
import { DATE_API_FORMAT } from "../../helpers/constant"
import { appProps } from "../../queries/extended/appProps"
import { AddPIDModal } from "../ClientPortfolio/ClientPortfolioDefinition/PIDNewModal"
import SortableTable from "../Shared/SortableTable"
import PlaceHolder from "../ui/PlaceHolder"

interface PlanRouteProps
  extends RouteComponentProps<{
    // To silent typescript on match.params.planId
    planId: string
  }> {}

interface PlanPortfoliosListOverviewFragment {
  data: PlanPortfoliosListQuery
  planId: number
  clientId: number | null | undefined
}

type idProps = {
  planId: number
  data: PlanPortfoliosListQuery
  auth: Auth
  match: match<{ planId: string }>
  clientId: number | null | undefined
  refetch: () => void
}

const DEFAULT_START_DATE = moment().format(DATE_API_FORMAT) // same setting as PIDNewModal
const DEFAULT_END_DATE = "9999-12-31" // same setting as PIDNewModal
const defaultCompositeMemberInput = { startDate: DEFAULT_START_DATE, endDate: DEFAULT_END_DATE }

const defaultNewComposite = {
  id: -1,
  name: "New Composite",
  isComposite: true,
  __typename: "ClientPortfolio",
} as ClientPortfolioBasicFragment

const getAvailableComposites = (data: PlanPortfoliosListQuery) => {
  let result = data?.plan?.clientPortfolios?.filter((portfolio) => !!portfolio?.isComposite) || []
  let sortedResult = _.orderBy(result, [(portfolio) => portfolio?.name?.toLowerCase()], ["asc"])
  return [defaultNewComposite, ...sortedResult]
}

const PlanPortfoliosListMain: React.FC<idProps> = ({ planId, data, auth, match, clientId, refetch }) => {
  const [search, setSearch] = useState("")
  const { resetErrors } = useContext(EditButtonContext)
  const { addAlert } = useContext(TemporaryAlertContext)

  // for add pids to existing composite
  const [saving, setSaving] = useState(false)
  // modal for new button
  const [isAddPIDModalOpen, setAddPIDModalOpen] = useState(false)

  // modal for add to composite
  const [isAddToCompositeModalOpen, setAddToCompositeModalOpen] = useState(false)

  // new/existing composite dropdown for add to composite button
  const [isCompositeDropdownOpen, setCompositeDropdown] = useState(false)

  const [gridApi, setGridApi] = useState<GridApi | undefined>(undefined)

  // select clientportfolios from tables
  const [selectedRows, setSelectedRows] = useState<ClientPortfolioDefinitionFragment[]>([])

  // available composite from the same plan
  const [compositeList, setCompositeList] = useState(getAvailableComposites(data))
  useEffect(() => {
    setCompositeList(getAvailableComposites(data))
  }, [data])

  /**
   * selected composite portfolio from add to composite dropdown
   * could be either number(id) or -1(new)
   */
  const [selectedComposite, setSelectedComposite] = useState<number>(-1)

  const [addPidsToComposite] = useUpdateClientPortfolioDefinitionMutation()

  // toggle modal for new button
  const toggleAddPIDModal = () => setAddPIDModalOpen(!isAddPIDModalOpen)
  // toggle modal for add to composite button
  const toggleAddToCompositeModal = () => setAddToCompositeModalOpen(!isAddToCompositeModalOpen)
  // toggle select new/existing composite from add to composite dropdown
  const toggleAddToCompositeDropdownCollapse = () => setCompositeDropdown(!isCompositeDropdownOpen)

  const selectComposite = (composite: ClientPortfolioBasicFragment) => {
    setSelectedComposite(composite.id)
    toggleAddToCompositeDropdownCollapse()
  }

  let colDef = PlanClientPortfolioListColumnDef()
  const onReady = (event: GridReadyEvent) => {
    setGridApi(event.api)
    // api.setFilterModel({
    //   'active': {
    //     filterType: 'set',
    //     values: ["Active"]
    //   }
    // })
  }

  const handleEnterKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    e.key === 'Enter' && e.preventDefault()
  }
  const showAddToCompositeButton = false

  function onBtExport() {
    gridApi!.exportDataAsCsv();
  }

  const heading = (
    <div className="pane pane-toolbar sticky-top">
      <Form className="mr-2 pr-1 border-right">
        <FormGroup row className="relative m-0 mr-1">
          <Input
            className="wide-search"
            type="text"
            placeholder="Search by name or ID"
            value={search}
            onChange={(e) => {
              setSearch(e.target.value)
            }}
            onKeyDown={handleEnterKeyDown}
          />
          <span className="o-88 absolute center-v right-1 pe-none">
            <FontAwesomeIcon icon={["fas", "search"]} size="2x" className="fontawesome-icon dark-icon-color text-gray-50" />
          </span>
        </FormGroup>
      </Form>
      <div className="border-right px-1">
        <Button color="link" className="mr-2 text-callan-blue border-blue-80 btn-thin" onClick={toggleAddPIDModal} disabled={!data}>
          New <FontAwesomeIcon icon="plus-circle" className="ml-2 text-blue-100" />
        </Button>
      </div>
      {showAddToCompositeButton &&
        <div className="add-to-composite-wrapper ml-1 px-2">
          <ButtonDropdown isOpen={isCompositeDropdownOpen} toggle={toggleAddToCompositeDropdownCollapse} className={"headline-dropdown add-to-composite-picker-dropdown"}>
            <DropdownToggle caret={true} className="mr-2 text-callan-blue border-blue-80 btn-thin" color="link" disabled={!data || selectedRows?.length === 0}>
              {"Add to Composite"}
            </DropdownToggle>
            <DropdownMenu onClick={(event) => event.stopPropagation()}>
              <ListGroup className="headline-dropdown-links">
                {compositeList?.map((composite) => {
                  if (!composite) {
                    return <React.Fragment key={"undefined"}></React.Fragment>
                  }
                  return (
                    <ListGroupItem
                      tag="a"
                      key={`composite-${composite?.id}`}
                      onClick={(event: React.MouseEvent<any, globalThis.MouseEvent>) => {
                        event.stopPropagation()
                        if (composite.id < 0) {
                          selectComposite(composite)
                          setAddToCompositeModalOpen(true)
                        } else {
                          setSaving(true)
                          let members = selectedRows.map((portfolio) => ({ ...defaultCompositeMemberInput, memberId: portfolio.id } as CompositeMemberInputItem))
                          addPidsToComposite({
                            variables: {
                              input: {
                                // selectedComposite doesn't work.
                                id: composite.id,
                                patch: { compositeMember: { add: members } },
                              },
                            },
                          })
                            .then((result) => {
                              setSaving(false)
                              if (result?.data) {
                                addAlert({title: "Added to Composite" ,message: `${selectedRows.length} item${selectedRows.length > 1 ? "s" : ""} added to ${composite.name}`})
                                toggleAddToCompositeDropdownCollapse()
                                // history.push(`/clientportfolios/${newId}?edibode=true`)
                              }
                            })
                            .catch((err) => {
                              setSaving(false)
                              console.log("error addPidsToComposite", err.message)
                              toggleAddToCompositeDropdownCollapse()
                            })
                        }
                      }}
                      className={classnames({ divider: composite?.id === -1 })}
                    >
                      {composite?.name}
                    </ListGroupItem>
                  )
                })}
              </ListGroup>
            </DropdownMenu>
          </ButtonDropdown>
        </div>
      }
      <div className="add-to-composite-wrapper">
        <ButtonDropdown isOpen={false} toggle={() => {}} className={"headline-dropdown add-to-composite-picker-dropdown"}>
          <DropdownToggle caret={true} className="mr-2 text-callan-blue border-blue-80 btn-thin" color="link" disabled={true}>
            {"Add to List"}
          </DropdownToggle>
          <DropdownMenu onClick={(event) => event.stopPropagation()}>
            <ListGroup className="headline-dropdown-links">
              {compositeList?.map((composite, idx) => {
                // if (!composite) {
                  return <React.Fragment key={idx}></React.Fragment>
                // }
                // return (
                //   <ListGroupItem
                //     tag="a"
                //     key={`composite-${composite?.id}`}
                //     onClick={(event: React.MouseEvent<any, globalThis.MouseEvent>) => {
                //       event.stopPropagation()
                //       if (composite.id < 0) {
                //         selectComposite(composite)
                //         setAddToCompositeModalOpen(true)
                //       } else {
                //         setSaving(true)
                //         let members = selectedRows.map((portfolio) => ({ ...defaultCompositeMemberInput, memberId: portfolio.id } as CompositeMemberInputItem))
                //         addPidsToComposite({
                //           variables: {
                //             input: {
                //               // selectedComposite doesn't work.
                //               id: composite.id,
                //               patch: { compositeMember: { add: members } },
                //             },
                //           },
                //         })
                //           .then((result) => {
                //             setSaving(false)
                //             if (result?.data) {
                //               console.log("addToComposite", result?.data.updateClientPortfolio?.clientPortfolio)
                //               toggleAddToCompositeDropdownCollapse()
                //               // history.push(`/clientportfolios/${newId}?editMode=true`)
                //             }
                //           })
                //           .catch((err) => {
                //             setSaving(false)
                //             console.log("error addPidsToComposite", err.message)
                //             toggleAddToCompositeDropdownCollapse()
                //           })
                //       }
                //     }}
                //     className={classnames({ divider: composite?.id === -1 })}
                //   >
                //     {composite?.name}
                //   </ListGroupItem>
                // )
              })}
            </ListGroup>
          </DropdownMenu>
        </ButtonDropdown>
      </div>
      <AddPIDModal
        key="single"
        modalOpen={isAddPIDModalOpen}
        setModalOpen={setAddPIDModalOpen}
        auth={auth}
        planId={planId}
        clientId={clientId}
        callBackIfSuccess={refetch}/>
      <AddPIDModal
        key="composite"
        modalOpen={isAddToCompositeModalOpen}
        setModalOpen={setAddToCompositeModalOpen}
        auth={auth}
        planId={planId}
        clientId={clientId}
        compositeByDefault={true}
        selectedIds={selectedRows.map((clientPortfolio) => clientPortfolio.id)}
        callBackIfSuccess={refetch}
      />
      <Button color="secondary btn-thin" className="text-callan-blue" onClick={()=> onBtExport()}>
        Export CSV
        <img src='/assets/CSV.svg' className="ml-2"/>
      </Button>
    </div>
  )

  return (
    <>
      <Container fluid className="d-flex flex-grow-1 flex-direction-column exportable" data-export-name="Portfolios">
        {heading}
        <SortableTable loading={false} filterText={search} columnDefs={colDef} tableData={data.plan?.clientPortfolios} rowId={"id"} onReady={onReady} setSelectedRows={setSelectedRows} />
      </Container>
    </>
  )
}

const useForceUpdate = () =>{
  // integer state
  const [value, setValue] = useState(0);
  // update the state to force render
  return () => setValue(value => value + 1);
}

const PlanPortfoliosListOverview: React.FC<appProps & PlanRouteProps & PlanPortfoliosListOverviewFragment> = (props) => {
  const { planId, auth, match, clientId } = props
  const { loading, error, data, refetch: _refetch } = usePlanPortfoliosListQuery({
    variables: { id: planId },
    fetchPolicy: "no-cache",
  })
  const { loading: userLoading, error: userError, data: userData } = useMeQuery({ fetchPolicy: "cache-first" })
  const user = userData?.me || undefined

  const [refreshPage, setRefresh] = useState(false)

  const refetchCallback = useCallback(() => { setTimeout(() => _refetch && _refetch(), 10) }, [_refetch])

  const forceUpdateNow = useForceUpdate()

  useEffect(() => {
    if(refreshPage) {
      // refetch query to add new data
      // refetchCallback()
      forceUpdateNow()
      refetchCallback()
      setRefresh(false)
    }
  }, [refreshPage])


  if ((loading && !data) || userLoading) {
    return (
      <Container fluid>
        <Row>
          <Col>
            <div className="pane pane-table">
              <PlaceHolder />
            </div>
          </Col>
        </Row>
      </Container>
    )
  }

  if (user === null) {
    return (
      <Container fluid>
        <Row>
          <Col>
            <div className="pane pane-table">
              <UncontrolledAlert color="danger">
                <h4>Invalid User</h4>
              </UncontrolledAlert>
            </div>
          </Col>
        </Row>
      </Container>
    )
  }

  if (error || userError) {
    return (
      <Container fluid>
        <Row>
          <Col>
            <div className="pane pane-table">
              <p>{error?.message}</p>
              <p>{userError?.message}</p>
            </div>
          </Col>
        </Row>
      </Container>
    )
  }

  if (!!data && data?.plan?.__typename === "Plan") {
    return <PlanPortfoliosListMain data={data} auth={auth} match={match} planId={planId} clientId={clientId} refetch={() =>setRefresh(true)}/>
  } else {
    return <div>data doesn't exist.</div>
  }
}

export default PlanPortfoliosListOverview
